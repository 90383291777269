import * as Constants from '../constants';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import { AiFillMinusSquare } from 'react-icons/ai';
import { AiFillPlusSquare } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { IoMdCheckmarkCircle } from 'react-icons/io';

function BreadList(prop) {

	const [bread, setBread] = useState([]);
	const [breadOrderException, setBreadOrderException] = useState();
	const [apartment, setApartment] = useState(prop.api.apartment ? prop.api.apartment : '');
	const [error, setError] = useState('');
	const [publicHoliday, setPublicHoliday] = useState(false);
	let timestamp = parseInt(new Date().getTime() / 1000);
	let today = new Date();
	let tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);
	let filter;

	useEffect(async () => {
		await fetchPublicHolidays()
		fetchBreadList();
		// 	console.log(apartment);
		// console.log(bread);
		// 	console.log(error);
		// console.log(prop.breadPage);
	}, []);

	const fetchPublicHolidays = async () => {
		const response = await fetch(Constants.baseUrl + '/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/publicHolidays',
				content: ''
			})
		})
		const res = await response.json()
		if(res.entries.find((el) => el.date === tomorrow.toLocaleDateString('en-CA'))) {
			setPublicHoliday(true);
			return filter = {
				active: true,
				everyDay: true
			}
		}
		else return filter = { active: true };
	};

	const fetchBreadList = async () => {
		await fetch(Constants.baseUrl + '/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/bread',
				content: {
					filter: filter
				}
			})
		})
			.then(res => res.json())
			.then(res => {
				res.entries.sort((a, b,) => a.number - b.number)
				setBread(res.entries);
			})
			.catch(function () {
				console.log('Fetch error');
			})
	};

	useEffect(() => {
		fetchBreadOrderException();
	}, []);
	const fetchBreadOrderException = async () => {
		await fetch(Constants.baseUrl + '/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/breadOrderDates'
			})
		})
			.then(data => data.json())
			// check if bread order has exception
			.then(data => {
				for (let index = 0; index < data.entries.length; index++) {
					const el = data.entries[index];
					if (new Date(el.date).setHours(0) === new Date().setHours(0, 0, 0, 0)) {
						setBreadOrderException({
							active: el.active,
							date: el.date,
							time: el.time,
							text: {
								de: el.text,
								it: el.text_it,
								en: el.text_en,
							}
						})
					}
				}
			})
	}

	// else console.log(bread);

	const Error = (data) => (
		<div className="breadOrder">
			<div className="error date">
				{data.msg}
			</div>
			<div className="bread-list flex-center flex-wrap">
				{bread.map((bread, i) => (
					<fieldset key={i} className="product flex-column space-between align-center">
						<img height="80" src={Constants.cms + bread.img.path} />
						<div className="product-title">{bread['title_' + Constants.lang]}</div>
						<div className="product-price">{Constants.priceLabel[Constants.lang]}: € {bread.price}</div>
					</fieldset>
				))}
			</div>
		</div>
	)

	const increment = (i, qnt) => {
		if (qnt < 12) {
			let copy = JSON.parse(JSON.stringify(bread));
			copy[i].quantity = Number(copy[i].quantity) + 1
			setBread(copy)
		}
	}
	const decrement = (i, qnt) => {
		if (qnt > 0) {
			let copy = JSON.parse(JSON.stringify(bread));
			copy[i].quantity = Number(copy[i].quantity) - 1
			setBread(copy)
		}
	}

	const Form = (data) => (
		<div className="breadOrder">
			<div className="date large">
				{data.msg}
			</div>
			{publicHoliday && <div className={`error date`}>{Constants.publicHoliday[Constants.lang]}</div>}
			<div className="bread-list flex-center flex-wrap">
				{bread.map((bread, i) => (
					<div key={i} className="product flex-column space-between align-center">
						<img height="80" src={Constants.cms + bread.img.path} />
						<div className="product-title">{bread['title_' + Constants.lang]}</div>
						<div className="product-price">{bread.price} €</div>
						<div className="qnt tac">
							<div className="conversion tac">
								<div className="counter flex-center align-center">
									<button className="minus large flex align-center" onClick={() => decrement(i, bread.quantity)}>
										<AiFillMinusSquare />
									</button>
									<div className="count tac large">{Number(bread.quantity)}</div>
									<button className="plus large flex align-center" onClick={() => increment(i, bread.quantity)}>
										<AiFillPlusSquare />
									</button>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="apartment">
				<div className="apartment-title large">{Constants.apartment[Constants.lang]}:</div>
				{prop.api.apartment === '' || prop.api.apartment === 'Frieda' ? <button className={(apartment === 'Frieda') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Frieda')}>Frieda</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Paula' ? <button className={(apartment === 'Paula') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Paula')}>Paula</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Martha' ? <button className={(apartment === 'Martha') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Martha')}>Martha</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Franzi' ? <button className={(apartment === 'Franzi') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Franzi')}>Franzi</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Luise' ? <button className={(apartment === 'Luise') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Luise')}>Luise</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Lotte' ? <button className={(apartment === 'Lotte') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Lotte')}>Lotte</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Greta' ? <button className={(apartment === 'Greta') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Greta')}>Greta</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Anni' ? <button className={(apartment === 'Anni') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Anni')}>Anni</button> : ''}
				{prop.api.apartment === '' || prop.api.apartment === 'Marie' ? <button className={(apartment === 'Marie') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Marie')}>Marie</button> : ''}
			</div>
			{error === 'apartment'
				? <div className="mandatory error">{Constants.noApartment[Constants.lang]}</div>
				: ''
			}
			{error === 'bread'
				? <div className="mandatory error">{Constants.noBread[Constants.lang]}</div>
				: ''
			}
			<button className="checkout button1 medium" onClick={() => {
				if (bread.reduce((total, currentValue) => total = total + Number(currentValue.quantity), 0) === 0) setError('bread');
				else if (apartment === '') setError('apartment');
				else {
					setError('');
					prop.setBreadPage('summary');
				}
			}}>
				{Constants.proceed[Constants.lang]}</button>
		</div>
	)

	let data;
	let order = [];
	let todayEntries = [];
	let todayEntriesString = '';
	let summary = {};

	const sendMail = (data) => {
		fetch(Constants.baseUrl + '/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/forms/submit/Brot',
				content: {
					form: {
						apartment: apartment,
						order: order
					}
				}
			})
		})
			.then(bread => (
				// Get all orders
				fetch(Constants.baseUrl + '/php/fetchApi.php', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						url: '/api/forms/export/Brot'
					})
				})
			))
			.then(bread => bread.json())
			// push to array
			.then(bread => {
				let todayDate = new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear();
				for (let i = 0; i < bread.length; i++) {
					let timestamp = new Date(bread[i]._created * 1000);
					let date = timestamp.getDate() + '.' + (timestamp.getMonth() + 1) + '.' + timestamp.getFullYear();
					// Get all today orders and save them to string
					if (todayDate === date) {
						todayEntries.push(bread[i].data);
						todayEntriesString += '<br/>' + timestamp.toLocaleString('de') + ' Bestellung von Apartment: ' + bread[i].data.apartment + '<br/>';
						for (let index = 0; index < bread[i].data.order.length; index++) {
							todayEntriesString += bread[i].data.order[index].name + ': ' + bread[i].data.order[index].quantity + '<br/>';
						}
					}
				}
				// console.log(todayEntries);
				// Summary of all orders by bread type
				for (let i = 0; i < todayEntries.length; i++) {
					todayEntries[i].order.forEach(function (d) {
						if (summary.hasOwnProperty(d.name)) {
							summary[d.name] = summary[d.name] + d.quantity;
						} else {
							summary[d.name] = d.quantity;
						}
					})
				}
				summary = JSON.stringify(summary);
				summary = summary.replaceAll(',', '<br/>').replaceAll('{', '').replaceAll('}', '').replaceAll('"', '').replaceAll(':', ': ');
				// console.log(summary);
				// console.log(todayEntriesString);
			})
			.then(bread => (
				fetch(Constants.baseUrl + '/php/mailer.php', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						order: data,
						summary: summary,
						all_orders: todayEntriesString
					})
				})
			))
			// Update notifications
			.then(bread => {
				let arr = [];
				if (localStorage.getItem('notifications')) arr = JSON.parse(localStorage.getItem('notifications'));
				let breadNotification = {
					text: Constants.order.de + ' ' + tomorrow.toLocaleString('de', { weekday: 'long' }) + ', ' + tomorrow.toLocaleString('de').split(',')[0] + '<br/>' + data,
					text_it: Constants.order.it + ' ' + tomorrow.toLocaleString('it', { weekday: 'long' }) + ', ' + tomorrow.toLocaleString('it').split(',')[0] + '<br/>' + data,
					text_en: Constants.order.en + ' ' + tomorrow.toLocaleString('en', { weekday: 'long' }) + ', ' + tomorrow.toLocaleString('en').split(',')[0] + '<br/>' + data,
					_created: timestamp,
					_new: true
				}
				arr.push(breadNotification);
				localStorage.setItem('notifications', JSON.stringify(arr));
				prop.setNewNote(!prop.newNote)
			})
			.then(bread => {
				// setKeycard('success');
				prop.setBreadPage('success');
			})
			.catch(error => {
				console.error('Problem: ', error);
			})
	}

	// Views
	const Bread = () => {
		if (prop.breadPage === 'list') {
			let bakery
			let msg
			let time
			// if order exception exists
			if (breadOrderException) {
				if (breadOrderException.text) msg = breadOrderException.text[Constants.lang]
				if (breadOrderException.time) time = breadOrderException.time.split(':')[0]
				if (breadOrderException.active === true) {
					// check if time is expired
					if (breadOrderException.time) {
						if (new Date().getHours() < time) bakery = true
						else {
							bakery = false
							// msg = Constants.breadOrderTooLate[Constants.lang]
						}
					}
				}
				else bakery = false
			}
			// normal: order before 5 pm & not on Saturday
			else {
				if (new Date().getHours() < 17 && new Date().getDay() !== 6) {
					bakery = true
					msg = Constants.order[Constants.lang] + ' ' + tomorrow.toLocaleString(Constants.lang, { weekday: 'long' }) + ', ' + tomorrow.toLocaleString(Constants.lang).split(',')[0]
				}
				// no order on Saturday
				else if (new Date().getDay() === 6) {
					bakery = false
					msg = Constants.breadOrderNotPossible[Constants.lang]
				}
				// order time expired
				else {
					bakery = false
					msg = Constants.breadOrderTooLate[Constants.lang]
				}
			}
			return (
				<>
					<div dangerouslySetInnerHTML={{ __html: Constants.breadText[Constants.lang] }}></div>
					{bakery === true ? <Form msg={msg} /> : <Error msg={msg} />}
				</>
			)
		}
		// summary view
		if (prop.breadPage === 'summary') {
			let counter = 0;
			data = Constants.apartment.de + ': ' + apartment + '<br/><br/>';
			bread.map((bread, i) => {
				if (bread.quantity !== null && bread.quantity !== 0 && bread.quantity !== '') {
					data += bread.title_de + ': ' + bread.quantity + 'x<br/>';
					order[counter] = {
						'name': bread.title_de,
						'quantity': bread.quantity,
						'number': bread.number,
						'automailorder': bread.automailorder
					};
					counter++;
				}
			});
			const cartSum = bread.reduce((sum, item) => {
				return sum + (Number(item.price.replaceAll(',', '.')) * item.quantity)
			}, 0)
			let cartTotal = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cartSum);
			data += '<br/>' + Constants.total.de + ': ' + cartTotal;
			window.scrollTo(0, 0);
			return (
				<div className="check-out">
					<div className="summary">
						<div className="medium t4">{Constants.summaryLabel[Constants.lang]}</div><br />
						<div className="summary-apartment">{Constants.apartment[Constants.lang] + ': ' + apartment}</div>
						<br />
						<div className="summary-list">
							{bread.map((bread, i) => {
								if (bread.quantity !== null && bread.quantity !== 0 && bread.quantity !== '') return (
									<div key={i} className="summary-product">
										{bread.quantity}x {bread['title_' + Constants.lang]}
									</div>
								)
							})}
						</div>
						<br />
						<div className="summary-price">{Constants.total[Constants.lang] + ': ' + cartTotal}</div>
					</div>
					<div className="flex-center space-between">
						<button className="change button1" onClick={() => prop.setBreadPage('list')}>{Constants.stop[Constants.lang]}</button>
						<button className="order button2 t5" onClick={() => sendMail(data)}>{Constants.orderNow[Constants.lang]}</button>
					</div>
				</div>
			)
		}
		// success view
		if (prop.breadPage === 'success') {
			window.scrollTo(0, 0);
			return (
				<div className="check-out tac">
					<div className="icon"><IoMdCheckmarkCircle fill='darkgreen' /></div>
					<div className="explain large">{Constants.orderSuccess[Constants.lang]}</div>
					<Link className="return button1" onClick={() => prop.setBreadPage('list')} to="/">
						{Constants.returnHome[Constants.lang]}
					</Link>
				</div>
			)
		}
	}

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='bread'>
			{!bread.length
				? <Spinner />
				: <Bread />
			}
		</motion.div>
	)

}

export default BreadList;