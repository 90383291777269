import * as Constants from '../constants';
import { motion } from 'framer-motion';

const ErrorPage = () => {
	return (
		<motion.div
			style={Constants.pageTransition.style}
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			transition={Constants.pageTransition.transition}>
			<h1>Page not found</h1>
		</motion.div>
	)
}

export default ErrorPage;