import * as Constants from '../constants';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import {AiFillMinusSquare} from 'react-icons/ai';
import {AiFillPlusSquare} from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import {IoMdCheckmarkCircle} from 'react-icons/io';

function GetProducts(prop) {

	let arr = [];
	let more = [];
	const [products, setProducts] = useState([]);
	const [readMore, setReadMore] = useState([]);

	useEffect(() => {
		fetchProducts();
	}, [])

	const fetchProducts = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/shop',
				content: {
					filter: {active:true}
				}
			})
		})
		.then(data => data.json())
		// push to array
		.then(data => {
			for (let i = 0; i < data.entries.length; i++) {
				arr.push(data.entries[i]);
			}
		})
		// sort array by #
		.then(() => {
			arr.sort((a, b) => a.number - b.number);
			setProducts(arr);
			// console.log(arr);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	// else console.log(products);

	const updateMore = (i) => {
		more[i] = true;
		setReadMore(more);
	}

	const ProductDetail = () => {
		return (
			<div className="product-list flex-wrap space-between">
				<div className="intro-text">
					{Constants.shop[Constants.lang]}
				</div>
				{products.map((product, i) => {
					return (
						<div key={i} className={'product product-'+(i+1)}>
							<div className="product-name t1">{product['product_'+Constants.lang]}</div>
							{product['producer_'+Constants.lang].length
								? <div className="product-producer t5">{product['producer_'+Constants.lang]}</div>
								: ''
							}
							<img className="product-image" src={[Constants.cms]+product.picture.path} />
							{product['desc_'+Constants.lang].length
								? <div className={readMore[i] === true ? "product-desc" : "product-desc short"}>{product['desc_'+Constants.lang]}</div>
								: ''
							}
							{product['desc_'+Constants.lang].length && readMore[i] !== true
								? <button className="read-more button2" onClick={() => updateMore(i)}>{Constants.readMore[Constants.lang]}</button>
								: ''
							}
							<div className="price-box">
								{product.sale ? <img className="product-sale" src="../images/sale-tag.png" /> : ''}
								<div className="product-price t2">
									{product.sale ? <div className="product-old-price small"><s>{product.price} €*</s></div> : ''}
									{product.sale ? <b>{product.sale}</b> : product.price} €*
								</div>
								<div className="product-quantity t5">{product.quantity}</div>
								<div className="price-disclaimer small">*{Constants.priceDisclaimer[Constants.lang]}</div>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<motion.div
		initial='initial'
		animate='in'
		exit='out'
		variants={Constants.pageTransition}
		style={Constants.pageTransition.style}
		transition={Constants.pageTransition.transition}
		className='shop'>
			{!products.length
				? <Spinner />
				: <ProductDetail />
			}
		</motion.div>
	)

}

export default GetProducts;