import { useState, useEffect } from 'react';
import Spinner from './Spinner';
import { motion } from 'framer-motion';
import * as Constants from '../constants';
import { useCookies } from "react-cookie";

function Popup(prop) {

	const [popup, setPopup] = useState([]);
	const [cookies, setCookies, removeCookies] = useCookies(['popup']);
	const [state, setState] = useState(true);

	useEffect(() => {
		fetchPopup();
	}, []);

	const fetchPopup = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/popup',
				content: {
					filter: {active:true},
					filter: {lang:Constants.lang}
				}
			})
		})
		.then(data => data.json())
		.then(data => {
			setPopup(data.entries);
		})
	}
	const variants1 = {
		open: {
			scale: [0.5,1]
		},
		closed: {
			scale: 0.5
		}
	}
	const variants2 = {
		open: {
			opacity: 1
		},
		closed: {
			opacity: 0,
			transitionEnd: {
				display: "none",
			}
		}
	}
	const Welcome = () => (
		<motion.div
			animate={state ? 'open' : 'closed'}
			variants={variants1}
		 	className="welcome">
			<img className="logo" src="./images/logo.svg" />
			{prop.api.name
				? <h1 className='t1'>{Constants.dearFamily[Constants.lang]} <span className='t1 surname'>{prop.api.name}!</span></h1>
				: ''
			}
			{popup.map((popup, index) => (
				<div key={index} dangerouslySetInnerHTML={{ __html: popup.text }}></div>
			))}
			<button className="button1" onClick={() => {
				setState(!state)
				setTimeout(() => setCookies('popup', true, {maxAge:  '604800'}), 200)
			}}>{Constants.letsGo[Constants.lang]}</button>
		</motion.div>
	)
	return (
		<motion.div
			animate={state ? 'open' : 'closed'}
			variants={variants2}
			className='popup flex-center align-center'>
			{(!popup.length) ? <Spinner /> : <Welcome />}
		</motion.div>
	)
}

export default Popup;