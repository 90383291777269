import React, { useState, useEffect, useRef } from 'react';
import {FaBell} from 'react-icons/fa';
import {VscClose} from 'react-icons/vsc';
import * as Constants from '../constants';
import { useCookies } from "react-cookie";
import { useLocation} from 'react-router-dom'

function Notification(prop) {

	const [active, setActive] = useState(false);
	const [count, setCount] = useState(0);
	let timestamp = parseInt(new Date().getTime()/1000);
	let timestampMidnight = new Date(new Date().setHours(23,59,59,0));
	const location = useLocation()

	// Cookies
	const [cookies, setCookies, removeCookies] = useCookies(['loginFirst', 'loginFirstToday', 'loginLast']);

	useEffect(() => {
		fetchNotifications()
	}, [prop.newNote, location.pathname])

	/**
	 * update notification cookie func
	 */
	 const updateNotifications = data => {
		let arr = [];
		// get current notifications
		if(localStorage.getItem('notifications')) arr = JSON.parse(localStorage.getItem('notifications'));
		// add new value
		if(data) {
			if(!data._new) data._new = true;
			// push new notifications if not already present
			if(data._id) {
				if(arr.filter(el => el._id === data._id).length === 0) arr.push(data);
			}
			else arr.push(data);
		}
		// sort notifications by date
		arr.sort((a,b,) => a._created - b._created)
		localStorage.setItem('notifications', JSON.stringify(arr))
		// Update new notification counter
		let counter = 0
		JSON.parse(localStorage.getItem('notifications')).map((notification, i) => {
			if(notification._new === true) counter++
		})
		setCount(counter);
	}

	/**
	 * Get and update notifications from cms
	 */
	const fetchNotifications = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/notifications',
				content: {
					limit: 5,
					sort: {_created:-1}
				}
			})
		})
		.then(res => res.json())
		.then(res => {
			// console.log(res.entries)
			// Check for new notifications since last login and push them to notifications cookie
			for (let index = 0; index < res.entries.length; index++) {
				let el = res.entries[index];
				if(el._created > Number(cookies.loginLast))	updateNotifications(el)
			}
		})
		.catch(function() {
			console.log('Fetch error');
		})
	}

	// fetch notifications and check for update
	useEffect(() => {
		let arr = [];
		if(localStorage.getItem('notifications')) arr = JSON.parse(localStorage.getItem('notifications'));
		// cleanup old notifications after 1 month
		arr = arr.filter(el => el._created > timestamp-2592000)
		// set main cookie: expires after 1 month
		if(!cookies.loginFirst) {
			let welcome = {
				text: `👋 Willkommen in der Dornsberg APP${prop.api.name ? `, Familie ${prop.api.name}` : ''}!<br/>Wir wünschen Ihnen einen schönen Aufenthalt! <a href="/contact">Sollten Sie Fragen haben, zögern Sie nicht, uns zu kontaktieren.</a>`,
				text_it: `👋 Benvenuti nella Dornsberg APP${prop.api.name ? `, famiglia ${prop.api.name}` : ''}!<br/>Vi auguriamo un buon soggiorno! <a href="/contact">Se avete domande, vi preghiamo di contattarci subito.</a>`,
				text_en: `👋 Welcome to the Dornsberg APP${prop.api.name ? `, family ${prop.api.name}` : ''}!<br/>We wish you a pleasant stay! <a href="/contact">If you have any questons, please contact us.</a>`,
				_created: timestamp,
				_new: true
			};
			arr.push(welcome);
			setCookies('loginFirst', timestamp, { path: '/', maxAge:  '2592000'});
		}
		// set last login cookie: expires after 1 month
		if(!cookies.loginLast) setCookies('loginLast', timestamp, { path: '/', maxAge:  '2592000'});
		// refresh last login cookie after 1 hour
		if(Number(cookies.loginLast) + 3600 <= timestamp) setCookies('loginLast', timestamp, { path: '/', maxAge:  '2592000'});
		// add morning post notification
		if(new Date().getHours() <= 9 && !cookies.loginFirstToday) {
			let morningPost = {
				text: '📰 Ihre Morgenpost ist da! <a href="/morning-post">Jetzt lesen</a>',
				text_it: '📰 Ecco la informazione giornaliera di Scena! <a href="/morning-post">Scopri di più</a>',
				text_en: '📰 Here is your morning post! <a href="/morning-post">Read more</a>',
				_created: timestamp,
				_new: true
			};
			arr.push(morningPost);
			// set daily cookie: expires at midnight
			if(!cookies.loginFirstToday) setCookies('loginFirstToday', timestamp, { path: '/', expires:  timestampMidnight});
		}
		localStorage.setItem('notifications', JSON.stringify(arr));
		updateNotifications()
		fetchNotifications()
	}, [])

	// check for new notification on focus
	useEffect(() => {
		window.addEventListener("focus", fetchNotifications);
		// Calls onFocus when the window first loads
		fetchNotifications()
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", fetchNotifications);
		};
	}, []);

	const toggleClass = () => {
		setActive(!active);
		// disable new msg after 1st display
		if(active === true) {
			let copy = JSON.parse(localStorage.getItem('notifications'));
			copy.map((notification, i) => {
				notification._new = false;
			})
			localStorage.setItem('notifications', JSON.stringify(copy));
			setCount(0)
		}
	};

	const Notifications = () => (
		<div className="notifications">
			{!localStorage.getItem('notifications')
				? 'No notifications'
				: JSON.parse(localStorage.getItem('notifications')).slice(0).reverse().map((notification, i) => {
					let text, time, timeBefore, timeText;
					// check if msg.text exists
					if(Constants.lang === 'de') text = notification.text
					if(Constants.lang === 'it') text = notification.text_it
					if(Constants.lang === 'en') text = notification.text_en
					if(text) {
						// calc time before msg post
						// seconds
						timeBefore = Math.floor(new Date().getTime()/1000) - new Date(notification._created)
						timeText = Constants.secondsLabel[Constants.lang];
						// minutes
						if(timeBefore >= 60) {
							timeBefore = Math.floor(timeBefore/60);
							timeBefore === 1 ? timeText = Constants.minuteLabel[Constants.lang] : timeText = Constants.minutesLabel[Constants.lang]
							// hours
							if(timeBefore >= 60) {
								timeBefore = Math.floor(timeBefore/60);
								timeBefore === 1 ? timeText = Constants.hourLabel[Constants.lang] : timeText = Constants.hoursLabel[Constants.lang]
								// days
								if(timeBefore >= 60) {
									timeBefore = Math.floor(timeBefore/24);
									timeBefore === 1 ? timeText = Constants.dayLabel[Constants.lang] : timeText = Constants.daysLabel[Constants.lang]
								}
							}
						}
						if(Constants.lang === 'de') time = `vor ${timeBefore} ${timeText}`
						if(Constants.lang === 'it') time = `${timeBefore} ${timeText} fa`
						if(Constants.lang === 'en') time = `${timeBefore} ${timeText} ago`
						return (
							<div key={i} className={notification._new === true ? "note new" : "note"}>
								<div className="note-time t5">{time}</div>
								<div className="note-msg" dangerouslySetInnerHTML={{__html: text}}></div>
							</div>
						)
					}
				})
			}
		</div>
	)

	const NewNotes = () => {
		return count !== 0 ? <div className="new-counter default">{count}</div> : ''
	}

	return (
		<>
			{active ? <Notifications /> : ''}
			<button className="notification" onClick={toggleClass}>
				{active === false ? <><FaBell /><NewNotes /></> : <VscClose />}
			</button>
		</>
	)
}

export default Notification;