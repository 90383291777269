import * as Constants from '../constants';

function GetDate() {

	return (
		<div className="date t5">
			{new Date().toLocaleString(Constants.lang, {weekday: 'long'})+', '+Constants.day+'.'+Constants.month+'.'+Constants.year}
		</div>
	)
}

export default GetDate;