import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';
import { useState, useEffect } from 'react';

const MorningPost = () => {

	const [frame, setFrame] = useState(false);

	return (
		<motion.div
			style={Constants.pageTransition.style}
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			transition={Constants.pageTransition.transition}>
			{frame === false
				? <Spinner />
				: ''}
			<iframe
				src={Constants.morningPostUrl[Constants.lang]+Constants.year+'-'+Constants.month+'-'+Constants.day+'&address=a262fcaa-cd19-484b-b165-8b63d650224c'}
				frameBorder="0"
				width="100%"
				height="100%"
				style={{height: 100+'vh'}}
				onLoad={() => setFrame(true)}>
			</iframe>
		</motion.div>
	)
}

export default MorningPost;