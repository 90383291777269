import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'; // https://react-hook-form.com/
import * as Constants from '../constants';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';
import {MdOutlineSearch} from 'react-icons/md';
import {MdOutlineRadioButtonChecked} from 'react-icons/md';
import {MdPlace} from 'react-icons/md';
import {MdPhone} from 'react-icons/md';
import {RiPinDistanceFill} from 'react-icons/ri';
import {VscGlobe} from 'react-icons/vsc';

function GetRestaurants() {

	const [loader, setLoader] = useState(false);
	const [singleton, setSingleton] = useState([]);
	const [entries, setEntries] = useState([]);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [search, setSearch] = useState('');
	const [searchState, setSearchState] = useState(false);
	const [location, setLocation] = useState('');
	const [category, setCategory] = useState('');
	const [sort, setSort] = useState('');
	const [readMore, setReadMore] = useState([]);

	useEffect(() => {
		fetchSingleton();
	}, []);

	useEffect(() => {
		setLoader(true)
		fetchEntries();
	}, []);


	const fetchSingleton = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/singletons/get/restaurants',
				content: ''
			})
		})
		.then(data => data.json())
		.then(data => {
			setSingleton(data);
			// console.log(data);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const fetchEntries = async () => {
		await fetch('api/restaurants/restaurants-'+Constants.lang+'.json')
		.then(data => data.json())
		.then(data => {
			setEntries(data)
			// console.log(data)
			// for (let index = 0; index < data.length; index++) {
			// 	const el = data[index];
			// 	console.log(el.metadata.contact_city)
			// }
		})
		.then(data => setLoader(false))
		.catch(function() {
			console.log('Fetch error')
		})
	}

	const filterOptions = data => {
		setLocation(data.location)
		setCategory(data.category)
		setSort(data.sort)
	}

	const handleInput = data => {
		const typingTimeout = setTimeout(() => {
			setSearch(data.target.value)
		}, 1000);
		return () => clearTimeout(typingTimeout)
	}

	const updateMore = (i) => {
		let copy = readMore
		copy[i] = !readMore[i];
		setReadMore([...copy]);
	}

	// calc straight line distance https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
	function calcDistance(lat1, lon1, lat2, lon2) {
		var R = 6371; // Radius of the earth in km
		var dLat = deg2rad(lat2-lat1);  // deg2rad below
		var dLon = deg2rad(lon2-lon1); 
		var a = 
			Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
			Math.sin(dLon/2) * Math.sin(dLon/2)
			; 
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		var d = R * c; // Distance in km
		return d;
	}
	function deg2rad(deg) {
		return deg * (Math.PI/180)
	}

	if(!Object.keys(singleton).length || !entries.length) return <Spinner />;
	else {
		let intro = {
			de: singleton.intro,
			it: singleton.intro_it,
			en: singleton.intro_en
		}
		return (
			<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className="restaurants merano-maps">
				{intro[Constants.lang]}
				<div className="entries flex-column">
					<div className="filter flex">
						<div className={searchState ? 'search open flex align-center' : 'search close flex align-center'}>
							<MdOutlineSearch onClick={() => {
								setSearchState(!searchState)
								document.querySelector('#search').focus()
							}} />
							<input id="search" type="text" placeholder={Constants.searchLabel[Constants.lang]} onChange={handleInput} autoComplete="off" />
						</div>
						<form className="flex" onChange={handleSubmit(filterOptions)}>
							<select name='location' {...register("location")}>
								<option value="">{Constants.allPlaces[Constants.lang]}</option>
								<option value="Schenna">{Constants.schenna[Constants.lang]}</option>
								<option value="Meran">{Constants.meranCity[Constants.lang]}</option>
								<option value="Tirol">{Constants.dorfTirol[Constants.lang]}</option>
								<option value={Constants.algund[Constants.lang]}>{Constants.algund[Constants.lang]}</option>
								<option value={Constants.marling[Constants.lang]}>{Constants.marling[Constants.lang]}</option>
								<option value="Lana">Lana</option>
								<option value={Constants.tscherms[Constants.lang]}>{Constants.tscherms[Constants.lang]}</option>
								<option value={Constants.partschins[Constants.lang]}>{Constants.partschins[Constants.lang]}</option>
								<option value="Martin">{Constants.stMartin[Constants.lang]}</option>
								<option value="Leon">{Constants.stLeonhard[Constants.lang]}</option>
							</select>
							<select id="category" name='category' {...register("category")}>
								<option value="">{Constants.allCategories[Constants.lang]}</option>
								<option id="favorits" value={singleton.favorits}>❤️ {Constants.ourFavorits[Constants.lang]}</option>
								<option value="106680">{Constants.restaurantLabel[Constants.lang]}</option>
								<option value="106684">{Constants.barLabel[Constants.lang]}</option>
								<option value="106681">{Constants.snackstationLabel[Constants.lang]}</option>
								<option value="107060">{Constants.alpLabel[Constants.lang]}</option>
								<option value="106682">Pizzeria</option>
								<option value="107051">Törggelen</option>
							</select>
							<select name='sort' {...register("sort")}>
								<option value="">{Constants.noSorting[Constants.lang]}</option>
								<option value="open">{Constants.openingHours[Constants.lang]}</option>
								<option value="distance">{Constants.distanceLabel[Constants.lang]}</option>
							</select>
						</form>
					</div>
					{loader ? <Spinner /> :
						entries.map((entry, index) => {
							entry.state = true
							entry.open = false
							let now = new Date().getTime()
							let day = [
								'Sun',
								'Mon',
								'Tue',
								'Wed',
								'Thu',
								'Fri',
								'Sat'
							]
							let today = day[new Date().getDay()]
							if(entry.metadata.openingHours) {
								let openingHours = JSON.parse(entry.metadata.openingHours)
								for (let index = 0; index < openingHours.length; index++) {
									const el = openingHours[index];
									if(el.fromDate.time && el.fromDate.time <= now && el.toDate.time && el.toDate.time >= now) {
										entry.open = {}
										entry.open = el
									}
								}
							}
							if(location && !entry.metadata.contact_city || location && entry.metadata.contact_city && !entry.metadata.contact_city.includes(location)) entry.state = false
							if(category && !category.includes(entry.id) && !entry.categories.includes(Number(category))) entry.state = false
							if(search && !entry.name.includes(search) && search && !entry.name.includes(search.charAt(0).toUpperCase()+search.slice(1))) entry.state = false
							return (
								<div key={index} className={entry.state ? 'entry tal' : 'none'}
									style={{order: sort === ''
										? 0
										: sort === 'open' && entry.open[today]
											? 1
											: sort === 'distance' && entry.geoObjects.length
												? Math.round(calcDistance(entry.geoObjects[0].coordinates[0].y,entry.geoObjects[0].coordinates[0].x,46.6803915,11.1922702).toFixed(2)*100)
												: 999999999
									}}
								>
									<div className="title t4">{entry.name}</div>
									<div className="details flex-wrap">
										{entry.open
											? <div className="open flex align-center"><MdOutlineRadioButtonChecked color={entry.open[today] ? 'green' : 'red'} /> 
												{entry.open[today]
													? Constants.todayLabel[Constants.lang]+' '+Constants.openLabel[Constants.lang]
													: Constants.todayLabel[Constants.lang]+' '+Constants.closedLabel[Constants.lang]
												}
											</div>
											: ''
										}
										{entry.metadata.contact_street && entry.metadata.contact_city
											? <div className="contact-street-city flex align-center"><MdPlace /> {entry.metadata.contact_street}, {entry.metadata.contact_city}</div>
											: ''
										}
										{entry.geoObjects.length
											? <div className="distance flex align-center"><RiPinDistanceFill />
												{new Intl.NumberFormat(Constants.lang, { style: 'decimal', maximumFractionDigits: 2}).format(
													calcDistance(entry.geoObjects[0].coordinates[0].y,entry.geoObjects[0].coordinates[0].x,46.6803915,11.1922702)
												)} km
												</div>
											: ''
										}
										{entry.metadata.contact_tel
											? <a className="tel medium flex align-center" href={'tel:'+entry.metadata.contact_tel}><MdPhone /> {entry.metadata.contact_tel}</a>
											: ''
										}
										{entry.metadata.link
											? <a className="link medium flex align-center" target="_blank" href={entry.metadata.link}><VscGlobe /> {entry.metadata.link}</a>
											: ''
										}
										{singleton.favorits.includes(entry.id)
											? <div className="favorit flex align-center">❤️ {Constants.favoritLabel[Constants.lang]}</div>
											: ''
										}
									</div>
									<div className="cta flex">
										<button className='detail button1' onClick={() => updateMore(index)}>{readMore[index] ? Constants.closeDetails[Constants.lang] : Constants.detailsLabel[Constants.lang]}</button>
										{entry.geoObjects.length
											? <a target="_blank" href={'https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=46.6803915%2C11.1922702&destination='+entry.geoObjects[0].coordinates[0].y+'%2C'+entry.geoObjects[0].coordinates[0].x} className='map button2'>{Constants.showRoute[Constants.lang]}</a>
											: <a target="_blank" href={'https://maps.merano-suedtirol.it/v2/'+Constants.lang+'/osm_tirol_summer/2d/-1/detail/-1/-1/-1//'+entry.id} className='map button2'>{Constants.showOnMap[Constants.lang]}</a>
										}
									</div>
									<div className={readMore[index] ? 'desc-wrapper' : 'none'}>
										<div className="desc" dangerouslySetInnerHTML={{ __html: entry.description}}></div>
										{entry.open
											? <div className='details flex-wrap'>
												{day.map((day,i) => {
													if(!entry.open[day]) return (
														<div key={i} className="flex align-center">
															<MdOutlineRadioButtonChecked color="red" /> {Constants.weekday[i][Constants.lang]} {Constants.closedLabel[Constants.lang]}
														</div>
													)
												})}
											  </div>
											: ''
										}
									</div>
								</div>
							)
						})}
				</div>
			</motion.div>
		)
	}

}

export default GetRestaurants;