import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';

function GetEvents() {

	// runs when the component is mount
	useEffect(() => {
		fetchEvents();
	}, []); // [] for run only once, when the compontent is mount

	// state holds the data
	const [events, setEvents] = useState([]); // [] for an empty array
	let counter = 0;
	let arr = [];
	let eventsCounter = 0;
	let today = new Date(new Date().setDate(new Date().getDate()));

	const fetchEvents = async () => {
		await fetch('https://tourism.opendatahub.bz.it/api/Event?pagenumber=1&pagesize=1000&locfilter=tvs522822E351CA11D18F1400A02427D15E')
		 	.then(function(data) {
				if (!data.ok) throw Error(data.statusText);
				return data.json();
			})
			.catch(function() {
				console.log('Fetch error');
			})
			.then(function GetNextEvents(data) {
				// day
				let date = new Date(new Date().setDate(new Date().getDate() + counter));
				// search for events at this day
				for (let i = 0; i < data.Items.length; i++) {
					// check if current language exists
					if(data.Items[i].HasLanguage.includes(Constants.lang)) {
						for (let index = 0; index < data.Items[i].EventDate.length; index++) {
							// push today events to array
							if(data.Items[i].EventDate[index].From === date.toISOString().slice(0,-14)+'T00:00:00'
							&& data.Items[i].Shortname !== 'Bustransfer München - Schenna') {
								// Skip today deprecated events
								if(date.toISOString().slice(0,-14)+'T00:00:00' === today.toISOString().slice(0,-14)+'T00:00:00'
								&& data.Items[i].EventDate[index].Begin.slice(0,2) <= new Date().getHours()) continue;
								arr.push(data.Items[i]);
								// add begin date to event
								arr[eventsCounter].DateBegin = data.Items[i].EventDate[index].From;
								arr[eventsCounter].DateBeginNumb = Number(data.Items[i].EventDate[index].From.slice(0,-9).replaceAll('-',''));
								// add begin time to event
								arr[eventsCounter].Begin = data.Items[i].EventDate[index].Begin;
								arr[eventsCounter].BeginNumb = Number(data.Items[i].EventDate[index].Begin.replaceAll(':',''));
								// add end time to event
								arr[eventsCounter].End = data.Items[i].EventDate[index].End;
								arr[eventsCounter].EndNumb = Number(data.Items[i].EventDate[index].End.replaceAll(':',''));
								// update counter
								eventsCounter++;
								if(eventsCounter === 10) return false;
							}
						}
					}
				}
				// get next 10 events
				if (arr.length < 10) {
					counter++;
					GetNextEvents(data);
				}
			})
			.then(function() {
				for (let index = 0; index < arr.length; index++) {
					// sort events with same date by time
					if(index > 0 && arr[index].DateBeginNumb === arr[index-1].DateBeginNumb) {
						arr.sort((a, b) => a.BeginNumb - b.BeginNumb);
					}
				}
			})
			.then(function SortArr() {
				// sort events by date
				arr.sort((a, b) => a.DateBeginNumb - b.DateBeginNumb);
			})
			// set events state
			.then(() => setEvents(arr))
		;
	};

	const Events = (prop) => {
		return (
			<>
				<h1 className="events-intro t4">{Constants.eventIntro[Constants.lang]}</h1>
				{events.map((event, index) => (
					<div key={index} className="event">
						<div className="event-date t1 large">{new Date(event.DateBegin).toLocaleDateString(Constants.lang)}</div>
						<div className="event-time t4">{event.Begin != null ? (event.Begin).slice(0,-3) : ''}{event.End != null ? ' - '+(event.End).slice(0,-3) : ''}</div>
						{/* <img className="event-image" src={'https://doc.lts.it/docsite/ImageRender.aspx?ID='+event.Id} /> */}
						{Object.keys(event.Detail[Constants.lang]).length ? <h2 className="event-name">{event.Detail[Constants.lang].Title}</h2> : ''}
						<div className="event-desc">{event.Detail[Constants.lang].BaseText}</div>
						{Object.keys(event.EventPrice).length ? <div className="event-price">{Constants.priceLabel[Constants.lang]}: {event.EventPrice[Constants.lang].Price} €</div> : ''}
						<div className="event-info">{Constants.moreInfoLabel[Constants.lang]}: {Constants.touristOffice[Constants.lang]}<br/>Mail: <a href="mailto:info@schenna.com">info@schenna.com</a> &bull; Tel: <a href="tel:+390473945669">+39 0473 945669</a></div>
					</div>
				))}
			</>
		)
	}

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className="events">
				{events.length < 10
					? <Spinner />
					: <Events />
				}
			</motion.div>
	)

}

export default GetEvents;





	// const Events = () => {
	// 	let counter = 0;
		// for (let i = 0; i < events.length; i++) {
		// 	// for (let index = 0; index < data.Items[i].EventDate.length; index++) {
		// 	// 	// push today events to array
		// 	// 	if(data.Items[i].EventDate[index].To == new Date().toISOString().slice(0,-16)+date+'T00:00:00') {
		// 	// 		events.push(data.Items[i]);
		// 	// 		// add begin time to event
		// 	// 		events[eventsCounter].Begin = data.Items[i].EventDate[index].Begin;
		// 	// 		// sort events by beginning time
		// 	// 		events.sort((a, b) => (a.Begin > b.Begin) ? 1 : -1);
		// 	// 		// update counter
		// 	// 		eventsCounter++;
		// 	// 	}
		// 	// }
		// }
	// 	return (
	// 		<div className="events">
	// 			{/* {events.map((event) => {
	// 				let date = new Date(new Date().setDate(new Date().getDate() + counter));
	// 				event.EventDate.map((eventDate) => {
	// 					if(eventDate.From == date.toISOString().slice(0,-14)+'T00:00:00') {
	// 						<div>{event.Detail.de.Title}</div>
	// 					}
	// 				})
	// 			})} */}
	// 		</div>
	// 	)
	// }

	// Fallback if data is not fetched yet
	// if(events == null) return <div className="events">loading...</div>;
	// else {
	// 	return (
	// 		// <Events />
	// 		test
	// 	)
	// }