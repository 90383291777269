import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';
import GetWeather from '../components/Weather';

function GetForecast() {

	// runs when the component is mount
	useEffect(() => {
		fetchWeather();
	}, []); // [] for run only once, when the compontent is mount

	// state holds the data
	const [forecasts, setForecasts] = useState([]); // [] for an empty array

	const fetchWeather = async () => {
	  	await fetch('https://api.tourism.testingmachine.eu/v1/Weather?extended=true&language='+Constants.lang)
			.then(data => data.json())
			.then(data => {
				setForecasts(data);
				// console.log(data);
			})
			.catch(function() {
				console.log('Fetch error');
			})
		;
	};

	const WeatherWarning = (prop) => {
		if(!JSON.parse(sessionStorage.getItem('weather'))) GetWeather()
		else var weather = JSON.parse(sessionStorage.getItem('weather'))
		if(weather) return (
			<div className="weather-warning">
				<br/>
				<div className='t4'>{Constants.precipitationLabel[Constants.lang]}</div>
				{weather.map((weather,index) => {
					if(index === Number(prop.index)) {
						let probability = [
							'0%',
							'0%-30% ('+weather.RainFrom+'-'+weather.RainTo+' mm)',
							'30%-60% ('+weather.RainFrom+'-'+weather.RainTo+' mm)',
							'60%-90% ('+weather.RainFrom+'-'+weather.RainTo+' mm)',
						]
						let thunderstorm = [
							'0%',
							'0%-30%',
							'30%-60%',
							'60%-90%',
						]
						return (
							<div className='weatherBulletin' key={index}>
								<p>{Constants.lang === 'it' ? Constants.clockLabel.it : ''} 0-6 {Constants.lang !== 'it' ? ' '+Constants.clockLabel[Constants.lang] : ''}: {probability[weather.Part1]}</p>
								<p>{Constants.lang === 'it' ? Constants.clockLabel.it : ''} 6-12 {Constants.lang !== 'it' ? ' '+Constants.clockLabel[Constants.lang] : ''}: {probability[weather.Part2]}</p>
								<p>{Constants.lang === 'it' ? Constants.clockLabel.it : ''} 12-18 {Constants.lang !== 'it' ? ' '+Constants.clockLabel[Constants.lang] : ''}: {probability[weather.Part3]}</p>
								<p>{Constants.lang === 'it' ? Constants.clockLabel.it : ''} 18-24 {Constants.lang !== 'it' ? ' '+Constants.clockLabel[Constants.lang] : ''}: {probability[weather.Part4]}</p>
								<p>{Constants.thunderstormsLabel[Constants.lang]}: {thunderstorm[weather.Thunderstorm]}</p>
							</div>
						)
					}
				})}
			</div>
		)
		else return ''
	}

	const Weather = (prop) => {
		return (
			<div className={prop.day+' day'}>
				<div className="weatherDate">
					{Constants[prop.day+'Label'][Constants.lang]+', '+new Date(forecasts.Conditions[prop.index].date).toLocaleDateString(Constants.lang, {weekday: 'long'})}, {new Date(forecasts.Conditions[prop.index].date).toLocaleDateString()}
				</div>
				<h2 className="weatherTitle">
					{forecasts.Conditions[prop.index].Title}
				</h2>
				<img
					className="weatherIcon"
					src={'/images/weather/icon_'+(parseInt(forecasts.Stationdata[prop.stationdata].WeatherCode, 36) - 9)+'.svg'}
					alt={forecasts.Stationdata[prop.stationdata].WeatherDesc}
				/>
				<div className="weatherTemp t4">
					{forecasts.Stationdata[prop.stationdata].MinTemp}°/{forecasts.Stationdata[prop.stationdata].MaxTemp}°
				</div>
				<p className="weatherBulletin">
					{forecasts.Conditions[prop.index].Weatherdesc}<br/>
					{forecasts.Conditions[prop.index].WeatherCondition}<br/>
					{forecasts.Conditions[prop.index].Temperatures}<br/>
				</p>
				<div className="weatherTemp t4">
					<br/>{Constants.mountainWeather[Constants.lang]}<br/>
					{forecasts.Mountain[prop.index].Title}
				</div>
				<p className="weatherBulletin">
					{forecasts.Mountain[prop.index].Weatherdesc}<br/>
					{forecasts.Mountain[prop.index].Conditions}<br/>
				</p>
				<WeatherWarning index={prop.index} />
			</div>
		)
	}

	const Forecasts = () => {
		return (
			<div className="evolution flex-center flex-wrap">
				{forecasts.Forecast.map((forecast, index) => (
					<div key={index} className={'day weather-'+(index+1)}>
						<div className="weatherTitle">
							{new Date(forecast.date).toLocaleDateString(Constants.lang, {weekday: 'short'})}, {new Date(forecast.date).toLocaleDateString()}<br/>
						</div>
						<img
							className="weatherIcon"
							src={'/images/weather/icon_'+(parseInt(forecast.Weathercode, 36) - 9)+'.svg'}
							alt={forecast.Weatherdesc}
							/>
						<div className="weatherTemp">
							{forecast.TempMinmax}°/{forecast.TempMaxmax}°
						</div>
					</div>
				))}
			</div>
		)
	}

	return (
		<motion.div
		initial='initial'
		animate='in'
		exit='out'
		variants={Constants.pageTransition}
		style={Constants.pageTransition.style}
		transition={Constants.pageTransition.transition}
		className="forecast">
			{forecasts && forecasts.Conditions
				? <>
					<Weather day="today" index="0" stationdata="1" />
					{forecasts.Conditions[1] ? <Weather day="tomorrow" index="1" stationdata="7" /> : ''}
					<div className="weather-evolution t4">{Constants.weatherEvolution[Constants.lang]}</div>
					<h2 className="weather-evolution-title">{forecasts.evolutiontitle}</h2><br/>
					<p>{forecasts.evolution}</p>
					<br/>
					{forecasts.Forecast.length ? <Forecasts /> : ''}
					<div className="source small">
						{Constants.sourceLabel[Constants.lang]}: <a className='small' href="https://provinz.bz.it/wetter" target='_blank'>https://provinz.bz.it/wetter</a>
					</div>
				</>
				: <Spinner/>
			}
		</motion.div>
	)

}

export default GetForecast;