import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';

function GetBus() {

	// runs when the component is mount
	useEffect(() => {
		fetchBus();
	}, []); // [] for run only once, when the compontent is mount

	// state holds the data
	const [bus, setBus] = useState([]); // [] for an empty array

	const fetchBus = async () => {
	  	await fetch('https://efa.sta.bz.it/apb/XML_TRIP_REQUEST2?language=de&locationServerActive=1&convertStopsPTKernel2LocationServer=1&convertAddressesITKernel2LocationServer=1&convertCoord2LocationServer=1&convertCrossingsITKernel2LocationServer=1&convertPOIsITKernel2LocationServer=1&stateless=1&itOptionsActive=1&ptOptionsActive=1&sessionID=0&canChangeMOT=0&name_origin=Schenna,%20Tannerhof&type_origin=any&name_destination=Meran,%20Theaterplatz&type_destination=any&trITMOTvalue100=7&lineRestriction=231&outputFormat=JSON')
			.then(data => data.json())
			.then(data => {
				setBus(data.trips);
				console.log(data.trips);
			})
			.catch(function() {
				console.log('Fetch error');
			})
		;
	};
	console.log(bus);
	// Fallback if data is not fetched yet
	if(bus == '') return <Spinner />;
	else {
		return (
			<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className="bus">
				{bus.map((trip, index) => (
					<div key={index} className="trip" style={{padding: "20px"}}>
						<div className="duration">Dauer: {trip.duration}</div>
						<div className="start">Start: {trip.legs[0].points[0].name}</div>
						<div className="start-time">Abfahrt: {trip.legs[0].points[0].dateTime.time}</div>
						<div className="end">Ziel: {trip.legs[0].points[1].name}</div>
						<div className="start-time">Ankunft: {trip.legs[0].points[1].dateTime.time}</div>
						<div className="final-destination">Endstation: {trip.legs[0].mode.destination}</div>
						<div className="price">Preis: max. € {Math.max.apply(Math, trip.itdFare.fares.map(function(o) { return o.priceBrutto; }))}0</div>
						<div className="stops">
							<div className="stop-title">Haltestellen</div>
							{trip.legs[0].stopSeq.map((stop, i) => (
								<div key={i} className="stop">
									<div className="name">Haltestelle: {stop.name}</div>
									<div className="arrival">{(i == 0) ? 'Abfahrt:' +stop.ref.depDateTime : 'Ankunft: '+stop.ref.arrDateTime}</div>
								</div>
							))}
						</div>
					</div>
				))}
			</motion.div>
		)
	}

}

export default GetBus;