import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';

function GetInfo(prop) {

	useEffect(() => {
		fetchGuestmap();
		fetchGuestmapSingleton();
	}, []);

	let arr = [];
	const [entries, setEntries] = useState([]);

	const fetchGuestmap = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/Guestmap',
				content: {
					filter: {active:true},
					filter: {lang:Constants.lang}
				}
			})
		})
		.then(data => data.json())
		// push to array
		.then(data => {
			for (let i = 0; i < data.entries.length; i++) {
				arr.push(data.entries[i]);
			}
		})
		// sort array alphabetically and add to state
		.then(() => {
			arr.sort((a, b) => a.title.localeCompare(b.title));
			setEntries(arr);
			// console.log(arr);
		})
		.catch(function() {
			console.log('Fetch error');
		})
	}

	const [singleton, setSingleton] = useState('');
	const fetchGuestmapSingleton = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/singletons/get/guestmap'+[Constants.lang],
				content: {
					filter: {active:true}
				}
			})
		})
		.then(data => data.json())
		.then(data => {
			setSingleton(data.text);
		})
	}

	const Guestmap = () => (
		<div className="entries">
			{entries.map((entry, index) => {
				return (
					<div key={index} className={entry.group}>
						<h2 className="title">{entry.title}</h2>
						<div className="entry default" dangerouslySetInnerHTML={{ __html: entry.text}}></div>
					</div>
				)
			})}
			<div className="end" dangerouslySetInnerHTML={{ __html: singleton }}></div>
		</div>
	)

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='guestmap'>
			{(entries == '') ? <Spinner /> : <Guestmap />}
		</motion.div>
	)

}

export default GetInfo;