import * as Constants from '../constants';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import DatePicker, { registerLocale } from 'react-datepicker'; // https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import { addDays, parseISO } from 'date-fns';
import de from "date-fns/locale/de";
import it from "date-fns/locale/it";
import {TiDelete} from 'react-icons/ti'
import { Link, useLocation } from 'react-router-dom';
import {IoMdCheckmarkCircle} from 'react-icons/io';
registerLocale('de', de)
registerLocale('it', it)


function RentalService(prop) {

	const [apartment, setApartment] = useState(prop.api.apartment ? prop.api.apartment : '');
	const [rental, setRental] = useState([]);
	const [rentalContent, setRentalContent] = useState({});
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState('E-Bike, eBike, eBike')
	const [page, setPage] = useState('list');
	const [readMore, setReadMore] = useState([]);
	let timestamp = parseInt(new Date().getTime()/1000);
	let more = [];

	useEffect(() => {
		fetchRental()
		// fetchCategories()
	}, []);

	const fetchRental = async () => {
		// get text content
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/singletons/get/rental',
			})
		})
		.then(data => data.json())
		.then(data => {
			let intro = {
				de: data.intro,
				it: data.intro_it,
				en: data.intro_en
			}
			let contract = {
				de: data.contract,
				it: data.contract_it,
				en: data.contract_en
			}
			let success = {
				de: data.success,
				it: data.success_it,
				en: data.success_en
			}
			setRentalContent({intro,contract,success})
		})
		// get rental items
		.then(() => {
			fetch(Constants.baseUrl+'/php/fetchApi.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					url: '/api/collections/get/rental',
					content: {
						filter: {active:true}
					}
				})
			})
			.then(data => data.json())
			.then(data => {
				let products = []
				data.entries.map((item,i) => {
					// check if item is available date
					// if(new Date() >= new Date(item.valid_from) && new Date() <= new Date(item.valid_to)) {
						products.push({
							product: item,
							dates: []
						})
					// }
				})
				setRental(products)
				// if no rental items are available
				if(!products.length) {
					setPage('no items available')
					setRental('no items available')
				}
			})
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const fetchCategories = async () => {
		fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/collection/rental'
			})
		})
		.then(collection => collection.json())
		.then(collection => {
			let filter = collection.fields.filter((field) => field.name === 'category');
			setCategories(filter[0].options.options);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const Category = () => {
		if(categories.length) return (
			<div className="categories flex-center flex-wrap">
				{categories.map((cat, index) => {
					let catLang;
					if(Constants.lang === 'de') catLang = cat.value.split(', ')[0]
					if(Constants.lang === 'it') catLang = cat.value.split(', ')[1]
					if(Constants.lang === 'en') catLang = cat.value.split(', ')[2]
					return (
						<button key={index}
							className={cat.value === category ? 'category button2' : 'category button1'}
							onClick={() => setCategory(cat.value)}>
								{catLang}
						</button>
					)
				})}
			</div>
		)
		else return <Spinner/>
	}

	const updateMore = (i) => {
		more[i] = true;
		setReadMore(more);
	}

	const RentalService = () => {
		// Views
		if(page === 'list') return (
			<>
				<div dangerouslySetInnerHTML={{__html: rentalContent.intro[Constants.lang]}}></div>
				{/* <Category /> */}
				<br/>
				<div className="rental-list flex-wrap space-between">
					{rental.map((rental, i) => {
						let product = rental.product
						let name = {
							de: product.name,
							it: product.name_it,
							en: product.name_en
						}
						let description = {
							de: product.desc,
							it: product.desc_it,
							en: product.desc_en
						}
						let details = {
							de: product.details,
							it: product.details_it,
							en: product.details_en
						}
						return (
							<div key={i} className={'rental rental-'+(i+1)}>
								<div className="rental-name t4">{name[Constants.lang]}</div>
								{product.image
									? <img className="rental-image" src={[Constants.cms]+'/'+product.image.path} />
									: ''
								}
								{description[Constants.lang].length
									? <div className='product-desc'>{description[Constants.lang]}</div>
									: ''
								}
								{details[Constants.lang]
								? <div className={readMore[i] === true ? 'rental-details' : 'rental-details short'} dangerouslySetInnerHTML={{__html: details[Constants.lang]}}></div>
								: ''
								}
								{details[Constants.lang] && readMore[i] !== true
									? <div className="read-more" onClick={() => updateMore(i)}>{Constants.readMore[Constants.lang]}</div>
									: ''
								}
								<div className="price-box">
									<div className="product-price t2">
										{product.price} €/{Constants.dayLabel[Constants.lang]}*
									</div>
									<div className="price-disclaimer small">*{Constants.priceDisclaimer[Constants.lang]}</div>
								</div>
								<Datepicker product={product} index={i} />
							</div>
						)
					})}
				</div>
			</>
		)
		else if (page === 'no items available') return Constants.noRentalItems[Constants.lang]
		else if (page === 'checkout') return <Checkout />
		else if (page === 'success') {
			window.scrollTo(0, 0);
			return (
				<div className="check-out tac">
					<div className="icon"><IoMdCheckmarkCircle fill='darkgreen' /></div>
					<div className="explain large">
					{rentalContent
						? <div dangerouslySetInnerHTML={{__html: rentalContent.success[Constants.lang]}}></div>
						: <Spinner />
					}
					</div>
					<Link className="return button1" onClick={()=>setPage('list')+prop.setNewNote(!prop.newNote)} to="/">
						{Constants.returnHome[Constants.lang]}
					</Link>
				</div>
			)
		}
		else if (page === 'loading') return <Spinner />
	}

	// Datepicker
	const Datepicker = (data) => {
		// get available days
		let availableArr = []
		const oneDay = 24 * 60 * 60 * 1000
		const availableDays = Math.round(Math.abs((new Date() - new Date(data.product.valid_to)) / oneDay))
		for (let index = 0; index <= availableDays+1; index++) {
			availableArr.push(addDays(new Date(), index))
		}
		// get unavailable days
		let disableDays = []
		for (let index = 0; index < data.product.dates.length; index++) {
			if(new Date(new Date(data.product.dates[index].value).setHours(0)) >= new Date(new Date().setHours(0, 0, 0, 0))) {
				disableDays.push(parseISO(data.product.dates[index].value))
			}
		}
		if(rental[data.index].dates.length) {
			for (let i = 0; i < rental[data.index].dates.length; i++) {
				disableDays.push(new Date(rental[data.index].dates[i]))
			}
		}
		// add date to selection
		const onChange = (date) => {
			let dateIsoString = new Date(new Date(date).getTime() - (new Date(date).getTimezoneOffset() * 60000)).toISOString().split('T00:00:00.000Z')[0]
			let copy = JSON.parse(JSON.stringify(rental))
			copy[data.index].dates.push(dateIsoString)
			// sort by date
			copy[data.index].dates.sort((a,b) => new Date(a) - new Date(b))
			setRental(copy)
		}
		return (
			<div className="datepicker tac">
				<Selection index={data.index} />
				<div className="button1 select-date">
					<DatePicker
						locale={Constants.lang}
						dateFormat="dd.MM.yyyy"
						calendarStartDay={1}
						selected={false}
						minDate={new Date() >= new Date(data.product.valid_from) ? new Date() : new Date(data.product.valid_from)}
						maxDate={new Date(data.product.valid_to)}
						highlightDates={availableArr}
						onChange={onChange}
						excludeDates={disableDays}
						withPortal
						placeholderText={rental[data.index].dates.length ? Constants.bookMoreLabel[Constants.lang] : Constants.bookingLabel[Constants.lang]}
						shouldCloseOnSelect={false}
						disabledKeyboardNavigation
						onFocus={e => e.target.blur()}
					>
						<div style={{ color: "#3dcc4a" }}>{Constants.calLegendAvailable[Constants.lang]}</div>
						<div style={{ color: "red" }}>{Constants.calLegendNotAvailable[Constants.lang]}</div>
					</DatePicker>
				</div>
				{rental[data.index].dates.length
					? <button onClick={() => setPage('checkout')} className="button2 medium proceed">
						{Constants.proceed[Constants.lang]}
					</button>
					: ''
				}
			</div>
		)
	}

	const Selection = (data) => {
		if(rental[data.index].dates.length) {
			return (
				<div className="selection">
					<div className="t4">{Constants.selectionLabel[Constants.lang]}:</div>
					<div className="selection-wrapper flex-wrap flex-center">
						{rental[data.index].dates.map((date, i) => (
							<div key={i} className="selection-date button2">
								<div className="day t1">
									{new Date(date).toLocaleDateString('de', {weekday:'short'})}
								</div>
								<div className="day large t4">
									{new Date(date).getDate()}
								</div>
								<div className="month default">
									{new Date(date).toLocaleDateString(Constants.lang, {month: 'long'})}
								</div>
								<div className="year small">
									{new Date(date).getFullYear()}
								</div>
								<TiDelete className='large' onClick={() => {
									let copy = JSON.parse(JSON.stringify(rental))
									copy[data.index].dates.splice(i, 1)
									setRental(copy)
								}} />
							</div>
						))}
					</div>
				</div>
			)
		}
		else return ''
	}

	const Checkout = (data) => {
		window.scrollTo(0, 0);
		if(!apartment.length) {
			return (
				<div className="apartment">
					<div className="apartment-title large">{Constants.apartment[Constants.lang]}:</div>
					{prop.api.apartment === '' || prop.api.apartment === 'Frieda' ? <button className={(apartment === 'Frieda') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Frieda')}>Frieda</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Paula' ? <button className={(apartment === 'Paula') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Paula')}>Paula</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Martha' ? <button className={(apartment === 'Martha') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Martha')}>Martha</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Franzi' ? <button className={(apartment === 'Franzi') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Franzi')}>Franzi</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Luise' ? <button className={(apartment === 'Luise') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Luise')}>Luise</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Lotte' ? <button className={(apartment === 'Lotte') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Lotte')}>Lotte</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Greta' ? <button className={(apartment === 'Greta') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Greta')}>Greta</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Anni' ? <button className={(apartment === 'Anni') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Anni')}>Anni</button> : ''}
					{prop.api.apartment === '' || prop.api.apartment === 'Marie' ? <button className={(apartment === 'Marie') ? 'active button2 large' : 'button2 large'} onClick={() => setApartment('Marie')}>Marie</button> : ''}
				</div>
			)
		}
		else {
			let cartSum = 0;
			let order = 'Apartment: '+apartment+'<br/><br/>'
			rental.map((rental, i) => {
				if(rental.dates.length) {
					let name = {
						de: rental.product.name,
						it: rental.product.name_it,
						en: rental.product.name_en
					}
					// calc sum euro
					rental.dates.map((date, i) => {
						cartSum = cartSum+Number(rental.product.price)
					})
					order += '<br/>'+name[Constants.lang]+'<br/>'
					rental.dates.map((date, i) => {
						order += new Date(date).toLocaleDateString(Constants.lang, {weekday: 'long'})+', '+new Date(date).toLocaleDateString(Constants.lang)+'<br/>'
					})
				}
			})
			let cartTotal = new Intl.NumberFormat(Constants.lang, { style: 'currency', currency: 'EUR' }).format(cartSum);
			order += '<br/>'+Constants.total[Constants.lang]+': '+cartTotal;
			return (
				<div className="check-out">
					<div className="summary">
						<div className="medium t4">{Constants.summaryLabel[Constants.lang]}</div><br/>
						<div className="summary-apartment">{Constants.apartment[Constants.lang]+': '+apartment}</div>
						<br/>
						<div className="summary-list">
							{rental.map((rental, i) => {
								if(rental.dates.length) {
									let name = {
										de: rental.product.name,
										it: rental.product.name_it,
										en: rental.product.name_en
									}
									return (
										<div key={i} className="summary-product">
											<div className="product-name">
												{name[Constants.lang]}
											</div>
											<div className="rental-dates">
												{rental.dates.map((date, i) => {
													return (
														<div key={i} className="rental-date">
															{new Date(date).toLocaleDateString(Constants.lang, {weekday: 'long'})}, {new Date(date).toLocaleDateString(Constants.lang)}
														</div>
													)
												})}
											</div>
											<br/>
										</div>
									)
								}
							})}
						</div>
						<div className="summary-price">{Constants.total[Constants.lang]+': '+cartTotal}</div>
					</div>
					<div className="flex-center">
						<button className="change button2" onClick={() => setPage('list')}>{Constants.stop[Constants.lang]}</button>
					</div>
					<br/>
					<div className="contract">
						{rentalContent
							? <div dangerouslySetInnerHTML={{__html: rentalContent.contract[Constants.lang]}}></div>
							: <Spinner />
						}
					</div>
					<button className="order button1 t5" onClick={() => sendMail(order)}>{Constants.sendRentalLabel[Constants.lang]}</button>
				</div>
			)
		}
	}

	const sendMail = (order) => {
		setPage('loading')
		let data = []
		rental.map((rental, i) => {
			if(rental.dates.length) {
				let dates = []
				if(rental.product.dates.length) dates = rental.product.dates
				rental.dates.map((date, i) => {
					dates.push({value: date})
				})
				let item = {
					_id: rental.product._id,
					dates: dates
				}
				data.push(item)
			}
		})
		// update booking dates on db
		fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/save/rental',
				content: {
					data: data
				}
			})
		})
		// update rental booking db
		.then(() => {
			let form = []
			for (var i = 0; i < rental.length; i++) {
				if(rental[i].dates.length) {
					let dates = []
					for (let index = 0; index < rental[i].dates.length; index++) {
						dates.push(rental[i].dates[index])
					}
					form[i] = {'name': rental[i].product.name, 'dates': dates};
				}
			}
			fetch(Constants.baseUrl+'/php/fetchApi.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					url: '/api/forms/submit/rental',
					content: {
						form: {
							apartment: apartment,
							order: form
						}
					}
				})
			})
			.then(() => (
				// send mail
				fetch(Constants.baseUrl+'/php/order.php', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						subject: 'Leihbuchung',
						content: order
					})
				})
			))
			// Update notifications
			.then(() => {
				let arr = [];
				if(localStorage.getItem('notifications')) arr = JSON.parse(localStorage.getItem('notifications'));
				let notification = {
					text: Constants.rentalServiceLabel.de+'<br/>'+order,
					text_it: Constants.rentalServiceLabel.it+'<br/>'+order,
					text_en: Constants.rentalServiceLabel.en+'<br/>'+order,
					_created: timestamp,
					_new: true
				}
				arr.push(notification);
				localStorage.setItem('notifications', JSON.stringify(arr));
			})
			.then(() => {
				setPage('success');
			})
		})
		.catch(error => {
			console.error(error);
		})
	}

	return (
		<div className="rental-service">
			{rental.length && rentalContent
				? <RentalService />
				: <Spinner />
			}
		</div>
	)

}

export default RentalService;