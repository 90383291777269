import * as Constants from '../constants';
import {FiShoppingCart} from 'react-icons/fi';

const ShoppingCart = (prop) => {

	if(prop.cart.length) {
		return (
			<div className="shopping-cart flex">
				<button className="cart-detail-link flex-center align-center" onClick={() => {
						prop.setPage('cart');
					}
				}>
					<FiShoppingCart stroke="white" />
					<div className="cart-article flex-center align-center">
						<div className="cart-length">{prop.cart.length}</div>
					</div>
				</button>
			</div>
		)
	}
	else return '';
}

export default ShoppingCart;