import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { motion } from 'framer-motion';
import * as Constants from '../constants';

function NavHeader(prop) {
	// scroll to top on each page change
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	if(location.pathname !== '/' && prop.breadPage !== 'summary' && prop.breadPage !== 'success') {
		return (
			<motion.nav
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={{width: '100vw'}}
			transition={Constants.pageTransition.transition}
			className="header flex align-center">
				<Link className="flex align-center" to="/">
					<IoIosArrowBack fill="white" />
				</Link>
				<span className="title t5 c4">{location.title}</span>
			</motion.nav>
		)
	}
	else return '';
}

export default NavHeader;