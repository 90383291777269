import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import { AnimatePresence } from 'framer-motion';
import BarcodeScannerComponent from "react-qr-barcode-scanner"; // https://github.com/jamenamcinteer/react-qr-barcode-scanner
import './App.css';
import * as Constants from './constants';
import Updater from './components/Updater';
import NavHeader from './components/NavHeader';
import ShoppingCart from './components/ShoppingCart';
import Notification from './components/Notification';
import Home from './pages/Home';
import ErrorPage from './pages/404';
import Info from './pages/Info';
import Forecast from './pages/Forecast';
import Bread from './pages/Bread';
import Events from './pages/Events';
import Bus from './pages/Bus';
import Active from './pages/Active';
import Restaurants from './pages/Restaurants';
import Shop from './pages/Shop';
import Favorits from './pages/Favorits';
import Contact from './pages/Contact';
import Popup from './components/Popup';
import Spinner from './components/Spinner';
import MorningPost from './pages/MorningPost';
import RentalService from './pages/RentalService';

function App() {

	const [cookies, setCookies, removeCookies] = useCookies(['popup']);
	const [api, setApi] = useState('');
	const [auth, setAuth] = useState('');
	const [breadPage, setBreadPage] = useState('list');
	const [newNote, setNewNote] = useState(false);
	const [cart, setCart] = useState([]);
	const [page, setPage] = useState('list');

	useEffect(() => {
		// url contains generic token: basic qr code
		if(window.location.pathname.search(/\bhello\b/) >= 0) {
			getAuth()
			window.history.pushState('','','/')
		}
		// url contains personal token
		else if(window.location.pathname.includes('?_id=')) {
			checkQrCode(window.location.href)
			window.history.pushState('','','/')
		}
		// url contains no token
		else checkToken();
	}, []);

	const checkToken = () => {
		fetch(Constants.baseUrl+'/php/token.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify('Authorisation request')
		})
		.then(data => data.json())
		.then(data => {
			// get token if auth failed
			if(data === 'Authentification failed') {
				// check cam device
				let cam = false;
				// Fallback for iPhone + Chrome
				if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
					setAuth('scanInfo')
				}
				else {
					navigator.mediaDevices.enumerateDevices()
					.then((devices) => {
						devices.map((device, i) => {
							if(device.kind === 'videoinput') cam = true
						})
					})
					.then(res => {
						if(cam === true) setAuth('scanInfo')
						else getAuth()
					})
					.catch(function(err) {
						alert(err.name + ": " + err.message);
					});
				}
			}
			else {
				setApi(data);
				setAuth(data.access)
			}
		})
	}
	const checkQrCode = (data) => {
		if (data === 'https://app.dornsberg.net/hello') getAuth('scan abort')
		else {
			fetch(Constants.baseUrl+'/php/fetchApi.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					url: '/api/collections/get/guests?filter[_id]='+data.split('https://app.dornsberg.net/?_id=')[1],
				})
			})
			.then(res => res.json())
			.then(res => {
				fetch(Constants.baseUrl+'/php/checkQrCode.php', {
					method: 'post',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(res.entries[0].token)
				})
				.then(res => res.json())
				.then(res => {
					if(res === 'access denied') getAuth('scan abort')
					else checkToken();
				})
			})
			.catch(function() {
				console.log('Fetch error');
			})
		}
	}
	const getAuth = (data) => {
		let token, url
		url = Constants.baseUrl+'/php/auth.php'
		if(window.location.search.includes('?token')) {
			token = window.location.search.split('?')[1]
			window.history.pushState('','','/')
			url = Constants.baseUrl+'/php/auth.php?'+token
		}
		fetch(url)
		.then(res => res.json())
		.then(res => {
			if(res === 'access denied' && data === 'scan abort') setAuth('scan abort')
			else if (res === 'access denied' && !data) setAuth(res)
			else checkToken();
		})
		.catch(function() {
			console.log('Fetch error');
		})
	};

	// wait for auth response
	if(!auth) return <Spinner />
	else {
		// Qr code msg
		if (auth === 'scanInfo') return (
			<div className="intro tac">
				<img className="logo" src="./images/logo.svg" />
				<p dangerouslySetInnerHTML={{__html: Constants.authScanQrCode[Constants.lang]}}></p>
				<button className="button1" onClick={() => setAuth('scan')}>
					{Constants.authScanButton[Constants.lang]}
				</button>
			</div>
		)
		// scan qr code
		else if (auth === 'scan') return (
			<div className="intro tac">
				<img className="logo" src="./images/logo.svg" />
				<div style={{height:370}} className='qrcode-scanner'>
					<BarcodeScannerComponent
						width={'100%'}
						height={370}
						onUpdate={(err, result) => {
							if(result) checkQrCode(result.text)
						}}
					/>
					<Spinner />
				</div>
				<button className="button2" onClick={() => getAuth('scan abort')}>
					{Constants.authSanAbort[Constants.lang]}
				</button>
			</div>
		)
		// scan does't work -> auth with ip
		else if (auth === 'scan abort') return (
			<div className="intro tac">
				<img className="logo" src="./images/logo.svg" />
				<p dangerouslySetInnerHTML={{__html: Constants.authWithIp[Constants.lang]}}></p>
				<button className="button1" onClick={() => getAuth('scan abort')}>
					{Constants.authButton[Constants.lang]}
				</button>
			</div>
		)
		// auth with ip
		else if (auth === 'access denied' && !api.departure) return (
			<div className="intro tac">
				<img className="logo" src="./images/logo.svg" />
				<p dangerouslySetInnerHTML={{__html: Constants.authText[Constants.lang]}}></p>
				<button className="button1" onClick={() => getAuth()}>
					{Constants.authButton[Constants.lang]}
				</button>
			</div>
		)
		// token expired - auth again
		else if (auth === 'access denied' && api.departure < parseInt(new Date().getTime()/1000)) return (
			<div className="intro tac">
				<img className="logo" src="./images/logo.svg" />
				<p dangerouslySetInnerHTML={{__html: Constants.authAgain[Constants.lang]}}></p>
				<button className="button1" onClick={() => getAuth()}>
					{Constants.authButton[Constants.lang]}
				</button>
			</div>
		)
		// token is ok
		else if (auth === 'access granted') {
			return (
				<Router>
					<div className="App">
						<main>
							<Route render={({location}) => (
								<AnimatePresence>
									<Switch location={location} key={location.pathname}>
										{(cookies.popup === 'true') ? <Route path ="/" exact component={Home} /> : <Route path="/" exact component={()=><Popup api={api}/>} />}
										<Route path ="/info" exact component={()=><Info api={api}/>} />
										<Route path ="/bread" exact render={() => ( <Bread api={api} cookies={cookies} breadPage={breadPage} setBreadPage={setBreadPage} newNote={newNote} setNewNote={setNewNote} /> )}  />
										<Route path ="/forecast" exact component={Forecast} />
										<Route path ="/gourmet-boutique" exact component={()=><Shop api={api}/>} />
										<Route path ="/rental" exact component={()=><RentalService api={api} newNote={newNote} setNewNote={setNewNote} />} />
										<Route path ="/favorits" exact component={()=><Favorits api={api}/>} />
										<Route path ="/events" exact component={Events} />
										<Route path ="/restaurants" exact component={Restaurants} />
										<Route path ="/bus" exact component={Bus} />
										<Route path ="/active" exact component={Active} />
										<Route path ="/contact" exact component={Contact} />
										<Route path ="/morning-post" exact component={MorningPost} />
										<Route component={ErrorPage} />
									</Switch>
								</AnimatePresence>
							)} />
						</main>
						<header>
							<NavHeader setBreadPage={setBreadPage} breadPage={breadPage} />
							<ShoppingCart cart={cart} setCart={setCart} page={page} setPage={setPage} />
						</header>
						<footer>
							<Notification api={api} newNote={newNote} setNewNote={setNewNote} />
						</footer>
					</div>
					<Updater api={api} newNote={newNote} setNewNote={setNewNote} checkToken={()=>checkToken()} />
				</Router>
			);
		}
	}

}

export default App;