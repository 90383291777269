import * as Constants from '../constants';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Spinner from '../components/Spinner';
import {AiFillMinusSquare} from 'react-icons/ai';
import {AiFillPlusSquare} from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import {IoMdCheckmarkCircle} from 'react-icons/io';

function GetFavorits(prop) {

	useEffect(() => {
		fetchFavorits();
	}, []);

	const [favorits, setFavorits] = useState([]);

	const fetchFavorits = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/favorits',
				content: {
					filter: {active:true}
				}
			})
		})
		.then(data => data.json())
		.then(data => {
			setFavorits(data.entries);
			// console.log(favorits);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	// else console.log(favorits);

	const Favorits = () => {
		return (
			<div className="favorits-list flex-wrap space-between">
				{favorits.map((favorit, i) => {
					return (
						<div key={i} className={'favorit favorit-'+(i+1)}>
							{favorit['subtitle_'+Constants.lang].length
								? <div className="favorit-subtitle t5">{favorit['subtitle_'+Constants.lang]}</div>
								: <div className="favorit-subtitle t5">{favorit['subtitle_de']}</div>
							}
							<div className="favorit-title t1">{favorit['title_'+Constants.lang]}</div>
							{favorit.image.length
								? <img className="favorit-image" src={[Constants.cms]+'/'+favorit.image} />
								: ''
							}
							{favorit['text_'+Constants.lang].length
								? <div className="favorit-text">{favorit['text_'+Constants.lang]}</div>
								: <div className="favorit-text">{favorit['text_de']}</div>
							}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<motion.div
		initial='initial'
		animate='in'
		exit='out'
		variants={Constants.pageTransition}
		style={Constants.pageTransition.style}
		transition={Constants.pageTransition.transition}
		className='favorits'>
			{!favorits.length
				? <Spinner />
				: <Favorits />
			}
		</motion.div>
	)

}

export default GetFavorits;