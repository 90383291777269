import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'; // https://react-hook-form.com/
import * as Constants from '../constants';
import Spinner from '../components/Spinner';
import { motion } from 'framer-motion';
import {MdOutlineSearch} from 'react-icons/md';
import {MdOutlineRadioButtonChecked} from 'react-icons/md';
import {MdAccessTime} from 'react-icons/md';
import {MdTrendingUp} from 'react-icons/md';
import {MdTrendingDown} from 'react-icons/md';
import {GiPathDistance} from 'react-icons/gi';

function GetActives() {

	const [loader, setLoader] = useState(false);
	const [singleton, setSingleton] = useState([]);
	const [activities, setActivities] = useState([]);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [search, setSearch] = useState('');
	const [searchState, setSearchState] = useState(false);
	const [type, setType] = useState('hiking');
	const [location, setLocation] = useState('');
	const [category, setCategory] = useState('');
	const [difficulty, setDifficulty] = useState('');
	const [sort, setSort] = useState('');
	const [readMore, setReadMore] = useState([]);

	useEffect(() => {
		fetchSingleton();
	}, []);

	useEffect(() => {
		setLoader(true)
		fetchActivities();
	}, [type]);


	const fetchSingleton = async () => {
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/singletons/get/hiking',
				content: ''
			})
		})
		.then(data => data.json())
		.then(data => {
			setSingleton(data);
			// console.log(data);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const fetchActivities = async () => {
		await fetch('api/active/'+type+'-'+Constants.lang+'.json')
		.then(data => data.json())
		.then(data => {
			if(type === 'biking') {
				let biking = data
				fetch('api/active/mtb-'+Constants.lang+'.json')
				.then(data => data.json())
				.then(data => {
					const ids = biking.map(el => el.id);
					for (let index = 0; index < data.length; index++) {
						const el = data[index];
						if(!ids.includes(Number(el.id))) biking.push(el)
						if(index === data.length-1) setActivities(biking)
					}
				})
			}
			else {
				setActivities(data)
			}
			// console.log(data)
			// for (let index = 0; index < activities.length; index++) {
			// 	const el = activities[index];
			// 	console.log(el.name, el.id)
			// }
		})
		.then(data => {
			if(document.querySelector('#favorits') && document.querySelector('#favorits').selected) setCategory(singleton[type+'_favorits'])
			else setCategory('')
		})
		.then(data => setLoader(false))
		.catch(function() {
			console.log('Fetch error')
		})
	}

	const filterOptions = data => {
		setType(data.type)
		setLocation(data.location)
		setCategory(data.category)
		setDifficulty(data.difficulty)
		setSort(data.sort)
	}

	const handleInput = data => {
		const typingTimeout = setTimeout(() => {
			setSearch(data.target.value)
		}, 1000);
		return () => clearTimeout(typingTimeout)
	}

	const updateMore = (i) => {
		let copy = readMore
		copy[i] = !readMore[i];
		setReadMore([...copy]);
	}

	if(!Object.keys(singleton).length || !activities.length) return <Spinner />;
	else {
		let intro = {
			de: singleton.intro,
			it: singleton.intro_it,
			en: singleton.intro_en
		}
		return (
			<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className="active merano-maps">
				{intro[Constants.lang]}
				<div className="entries flex-column">
					<div className="filter flex">
						<div className={searchState ? 'search open flex align-center' : 'search close flex align-center'}>
							<MdOutlineSearch onClick={() => {
								setSearchState(!searchState)
								document.querySelector('#search').focus()
							}} />
							<input id="search" type="text" placeholder={Constants.searchLabel[Constants.lang]} onChange={handleInput} autoComplete="off" />
						</div>
						<form className="flex" onChange={handleSubmit(filterOptions)}>
							<select name="type" {...register("type")}>
								<option value="hiking">{Constants.hiking[Constants.lang]}</option>
								<option value="biking">{Constants.biking[Constants.lang]}</option>
							</select>
							<select name='location' {...register("location")}>
								<option value="">{Constants.allRegions[Constants.lang]}</option>
								<option value="Schenna">{Constants.schenna[Constants.lang]}</option>
								<option value="Meran">{Constants.meran[Constants.lang]}</option>
								<option value={Constants.hafling[Constants.lang]}>{Constants.hafling[Constants.lang]}</option>
								<option value={Constants.moelten[Constants.lang]}>{Constants.moelten[Constants.lang]}</option>
								<option value={Constants.jenesien[Constants.lang]}>{Constants.jenesien[Constants.lang]}</option>
								<option value={Constants.passeiertal[Constants.lang]}>{Constants.passeiertal[Constants.lang]}</option>
								<option value={Constants.dorfTirol[Constants.lang]}>{Constants.dorfTirol[Constants.lang]}</option>
								<option value={Constants.algund[Constants.lang]}>{Constants.algund[Constants.lang]}</option>
								<option value={Constants.marling[Constants.lang]}>{Constants.marling[Constants.lang]}</option>
								<option value="Lana">Lana</option>
								<option value="Vigil">{Constants.vigiljoch[Constants.lang]}</option>
								<option value={Constants.partschins[Constants.lang]}>{Constants.partschins[Constants.lang]}</option>
								<option value={Constants.ultental[Constants.lang]}>{Constants.ultental[Constants.lang]}</option>
								<option value={Constants.tisensPrissian[Constants.lang]}>{Constants.tisensPrissian[Constants.lang]}</option>
								<option value={Constants.nals[Constants.lang]}>{Constants.nals[Constants.lang]}</option>
								<option value={Constants.naturns[Constants.lang]}>{Constants.naturns[Constants.lang]}</option>
							</select>
							<select id="category" name='category' {...register("category")}>
								<option value="">{Constants.allCategories[Constants.lang]}</option>
								<option id="favorits" value={singleton[type+'_favorits']}>❤️ {Constants.ourFavorits[Constants.lang]}</option>
								{type === 'hiking' ? <option value="106649">{Constants.waalwege[Constants.lang]}</option> : ''}
								{type === 'hiking' ? <option value="106656">{Constants.hightTrails[Constants.lang]}</option> : ''}
								{type === 'hiking' ? <option value="106652">{Constants.themedTrails[Constants.lang]}</option> : ''}
								{type === 'hiking' ? <option value="107042">{Constants.childBuggyTrip[Constants.lang]}</option> : ''}
								{type === 'biking' ? <option value="106658">{Constants.bikeTour[Constants.lang]}</option> : ''}
								{type === 'biking' ? <option value="106661">{Constants.roadBike[Constants.lang]}</option> : ''}
								{type === 'biking' ? <option value="106662">{Constants.bikeRentalService[Constants.lang]}</option> : ''}
								{type === 'biking' ? <option value="106660">{Constants.eBikeCharger[Constants.lang]}</option> : ''}
							</select>
							<select name='difficulty' {...register("difficulty")}>
								<option value="">{Constants.difficultyLevels[Constants.lang]}</option>
								<option value="0">{Constants.level0[Constants.lang]}</option>
								<option value="1">{Constants.level1[Constants.lang]}</option>
								<option value="2">{Constants.level2[Constants.lang]}</option>
							</select>
							<select name='sort' {...register("sort")}>
								<option value="">{Constants.noSorting[Constants.lang]}</option>
								<option value="difficulty">{Constants.difficultyLabel[Constants.lang]}</option>
								{type === 'hiking' ? <option value="time_total">{Constants.durationLabel[Constants.lang]}</option> : ''}
								<option value="route_length">{Constants.lengthLabel[Constants.lang]}</option>
								<option value="meter_up">{Constants.altimeterLabel[Constants.lang]}</option>
							</select>
						</form>
					</div>
					{loader ? <Spinner /> :
						activities.map((activity, index) => {
							activity.state = true
							let diffIcon
							let counter = 0
							if(location && !activity.metadata.contact_name || location && activity.metadata.contact_name && !activity.metadata.contact_name.includes(location)) activity.state = false
							if(category && !category.includes(activity.id) && !activity.categories.includes(Number(category))) activity.state = false
							if(difficulty && Number(difficulty) !== Number(activity.metadata.difficulty)) activity.state = false
							if(activity.metadata.difficulty === 0) diffIcon = 'darkblue'
							if(activity.metadata.difficulty === 1) diffIcon = 'firebrick'
							if(activity.metadata.difficulty === 2) diffIcon = 'black'
							if(search && !activity.name.includes(search) && search && !activity.name.includes(search.charAt(0).toUpperCase()+search.slice(1))) activity.state = false
							return (
								<div key={index} className={activity.state ? 'entry tal' : 'none'} style={{order: sort === '' ? 0 : Math.round(activity.metadata[sort]*100)}}>
									<div className="title t4">{activity.name}</div>
									<div className="details flex-wrap">
										{activity.metadata.difficulty !== undefined
											? <div className={'level flex align-center level-'+activity.metadata.difficulty}>
												<MdOutlineRadioButtonChecked color={diffIcon} />
												{Constants['level'+activity.metadata.difficulty][Constants.lang]}
											</div>
											: ''
										}
										{activity.metadata.time_total
											? <div className="duration flex align-center"><MdAccessTime /> {new Intl.NumberFormat(Constants.lang, { style: 'decimal', maximumFractionDigits: 2}).format(activity.metadata.time_total)} h</div>
											: ''
										}
										{activity.metadata.route_length
											? <div className="length flex align-center"><GiPathDistance /> {new Intl.NumberFormat(Constants.lang, { style: 'decimal', maximumFractionDigits: 2}).format(activity.metadata.route_length)} km</div>
											: ''
										}
										{activity.metadata.meter_up
											? <div className="alt-up flex align-center"><MdTrendingUp /> {activity.metadata.meter_up} m</div>
											: ''
										}
										{activity.metadata.meter_down
											? <div className="alt-down flex align-center"><MdTrendingDown /> {activity.metadata.meter_down} m</div>
											: ''
										}
										{type === 'hiking' && activity.categories.includes(107042)
											? <div className="child-buggy-trip flex align-center">🚼 {Constants.childBuggyTrip[Constants.lang]}</div>
											: ''
										}
										{singleton[type+'_favorits'].includes(activity.id)
											? <div className="favorit flex align-center">❤️ {Constants.favoritLabel[Constants.lang]}</div>
											: ''
										}
									</div>
									<div className="cta flex">
										<button className='detail button1' onClick={() => updateMore(index)}>{readMore[index] ? Constants.closeDetails[Constants.lang] : Constants.detailsLabel[Constants.lang]}</button>
										<a target="_blank" href={'https://maps.merano-suedtirol.it/v2/'+Constants.lang+'/osm_tirol_summer/2d/-1/detail/-1/-1/-1//'+activity.id} className='map button2'>{Constants.showOnMap[Constants.lang]}</a>
									</div>
									<div className={readMore[index] ? 'desc-wrapper' : 'none'}>
										{activity.mediums.map((medium,i) => {
											if(!medium.src.includes('imagerender') && counter === 0) {
												counter++
												return readMore[index] ? <img key={i} width="auto" style={{maxHeight:180, maxWidth:'100%'}} src={medium.baseUrl+medium.src} /> : ''
											}
										})}
										<div className="desc" dangerouslySetInnerHTML={{ __html: activity.description}}></div>
									</div>
								</div>
							)
						})}
				</div>
			</motion.div>
		)
	}

}

export default GetActives;