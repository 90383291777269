import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../constants';
import Spinner from './Spinner';
import {MdKeyboardArrowRight} from 'react-icons/md'

function GetWeather() {
	// Storage
	const SessionStorage = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('weather') || '[]') : null;
	const currTimestamp = new Date().getTime();
	const hour = 3600000;
	// update storage if its older than an hour or not exists
	if (currTimestamp - hour >  sessionStorage.getItem('weatherTimeStamp')) {
		sessionStorage.setItem('weatherTimeStamp', currTimestamp);
		sessionStorage.removeItem('weather');
		console.log('update weather storage');
	}

	// state holds the data
	const [weather, setWeather] = useState(SessionStorage); // preload with session storage if exists
	// runs when the component is mount
	useEffect(() => {
		if(weather == '') {
			fetchWeather();
		}
	}, []); // [] for run only once, when the compontent is mount

	const fetchWeather = async () => {
	  	await fetch('https://tourism.opendatahub.bz.it/v1/Weather/District?locfilter=tvs522822E351CA11D18F1400A02427D15E&language='+Constants.lang)
			.then(data => data.json())
			.then(data => {
				setWeather(data.BezirksForecast);
				sessionStorage.setItem('weather', JSON.stringify(data.BezirksForecast));
				// console.log(data);
			})
			.catch(function() {
				console.log('Fetch error');
			})
		;
	};

	const WeatherWarning = () => {
		let state, day, time, rainFrom, rainTo, rain, probability, thunderstorms
		if(weather.length) {
			for (let index = 0; index < 2; index++) {
				const el = weather[index];
				for (let i = 1; i <= 4; i++) {
					if(el['Part'+i] > 0 && new Date() < new Date(new Date(el.date).setHours(6*i,0,0,0))) {
						index === 0 ? day = Constants.todayLabel[Constants.lang] : day = Constants.tomorrowLabel[Constants.lang]
						time = 6*(i-1)+'-'+6*i
						rainFrom = el.RainFrom
						rainTo = el.RainTo
						probability = Constants['probability'+el['Part'+i]][Constants.lang]
						thunderstorms = el.Thunderstorm
						state = true
						break
					}
				}
				if(state) break
			}
			if(rainTo >= 5) rain = Constants.lightRain[Constants.lang]
			if(rainTo < 5 && rainTo >= 10) rain = Constants.moderateRain[Constants.lang]
			if(rainTo > 10) rain = Constants.heavyRain[Constants.lang]
		}
		if(state) return (
			<div className="weather-warning small center">
				{day}, {Constants.lang === 'it' ? Constants.clockLabel.it : ''} {time}{Constants.lang !== 'it' ? ' '+Constants.clockLabel[Constants.lang] : ''}: {rain} {probability}
				{thunderstorms > 0 ? ' & '+Constants.thunderstormsLabel[Constants.lang]+' '+Constants['probability'+thunderstorms][Constants.lang]+'. ' : '.'}
				<span className='small flex align-center' style={{display: 'inline-flex'}}>{Constants.discoverMore[Constants.lang]} <MdKeyboardArrowRight /></span>
			</div>
		)
		else return ''
	}

	// Fallback if data is not fetched yet
	if(!weather.length) return <Spinner />;
	else {
		return (
			<Link className="weather" to={{pathname:"forecast", title:Constants.weatherLabel[Constants.lang]}}>
				<div className="flex-center">
					{weather.map((weather, index) => {
						if(index > 1) return false;
						else {
							return (
								<div key={index} className={'weather-details weather-'+(index+1)}>
									<img
										className="weatherIcon"
										src={'/images/weather/icon_'+(parseInt(weather.WeatherCode, 36) - 9)+'.svg'}
										alt={weather.WeatherDesc}
									/>
									<div className="weatherDesc">
										<div className="weatherTitle t5">
											{(index === 0) ? Constants.todayLabel[Constants.lang] : Constants.tomorrowLabel[Constants.lang]}
										</div>
										<div className="weatherTemp small">
											{weather.MinTemp}°/{weather.MaxTemp}°
										</div>
									</div>
								</div>
							)
						}
					})}
				</div>
				<WeatherWarning />
			</Link>
		)
	}

}

export default GetWeather;