import { useEffect } from "react";
import packageJson from "../../package.json"
import { useCookies } from "react-cookie";

/**
 * Keep the app updated with reload page
 */
const Updater = (prop) => {

	const [cookies, setCookies, removeCookies] = useCookies(['focus']);

	// current timestamp in seconds
	let timestamp = parseInt(new Date().getTime()/1000);

	useEffect(() => {
		console.log(packageJson.version)
		window.addEventListener("focus", onFocus);
		// Calls onFocus when the window first loads
		onFocus();
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, []);

	// if window is focused and not updated for 24h hours
	const onFocus = () => {
		// check if auth token exists every 2 hours
		// if(Number(cookies.loginLast) + 7200 <= timestamp && Number(cookies.loginLast) + 86400 >= timestamp) prop.checkToken()
		// check if last login is bigger than 2h - than reload
		if(Number(cookies.loginLast) + 7200 < timestamp) window.location.reload()
	};

	return <></>;
};

export default Updater;