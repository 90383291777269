import * as Constants from '../constants';
import { motion } from 'framer-motion';
import {FiPhone} from 'react-icons/fi'
import {FiMail} from 'react-icons/fi'
import {ImWhatsapp} from 'react-icons/im'

const ErrorPage = () => {
	return (
		<motion.div
			style={Constants.pageTransition.style}
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			transition={Constants.pageTransition.transition}>
				<h1 className="medium">{Constants.contactIntro[Constants.lang]}</h1>
				<nav className="main">
					<ul className="flex-center flex-wrap">
						<li className="link flex-center align-center">
							<a href="tel:+390473943028" className="flex-center align-center flex-column">
								<div className="circle flex-center align-center">
									<FiPhone />
								</div>
								<div className="title small">{Constants.phoneLabel[Constants.lang]}</div>
							</a>
						</li>
						<li className="link flex-center align-center">
							<a href="mailto:info@dornsberg.net" className="flex-center align-center flex-column">
								<div className="circle flex-center align-center">
									<FiMail />
								</div>
								<div className="title small">{Constants.mailLabel[Constants.lang]}</div>
							</a>
						</li>
						<li className="link flex-center align-center">
							<a href="https://wa.me/+390473943028" className="flex-center align-center flex-column">
								<div className="circle flex-center align-center">
									<ImWhatsapp />
								</div>
								<div className="title small">WhatsApp</div>
							</a>
						</li>
					</ul>
				</nav>
		</motion.div>
	)
}

export default ErrorPage;